import { LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message, notification, Space, Spin, Table } from 'antd';
import { fetchLinkedinData, getLinkedInData } from 'API/Linkedin';
import { JobContext } from 'App';
import NewScanModal from 'components/modal/newScanModal';
import ViewHistoryModal from 'components/modal/viewHistoryModal';
import { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
const { Column } = Table;

const LinkedIn = (props) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const { filter } = useContext(JobContext);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [scanResponse, setScaneResponse] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const incomingData = await getLinkedInData(tableParams, filter ? filter : null);
      setTableData(() => incomingData?.results);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: incomingData.count,
        },
      });

      setLoading(false);
    };

    loadData();
  }, [filter, JSON.stringify(tableParams)]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
    }
  };

  const handleNewButtonClick = () => {
    setOpenNewModal(true);
  };

  const handleHistoryButtonClick = () => {
    setOpenHistoryModal(true);
  };

  const openNotification = (type, data) => {
    type === 'success'
      ? notification.success({
          message: data,
        })
      : type === 'failed'
      ? notification.error({
          message: data,
        })
      : type === 'completed'
      ? notification.success({
          message: data.Message,
          description: `Fetched Jobs: ${data.Fetched_jobs}`,
        })
      : notification.open({
          message: data,
        });
  };

  const onFinish = async (keyword, location) => {
    openNotification('success', 'Scanning Started');
    const res = await fetchLinkedinData(keyword, location);
    res?.Message ? openNotification('success', res) : openNotification('failed', 'Scanning Failed');
  };

  return (
    <div className="linkedin-body">
      <div className="button-wrapper">
        <Button className="action-buttons" onClick={handleNewButtonClick} disabled={loader}>
          Scan Jobs
          {loader && <Spin size="small" style={{ marginLeft: '10px' }} />}
        </Button>
        <Button className="action-buttons" onClick={handleHistoryButtonClick}>
          View Scanned History
        </Button>
      </div>
      <Table
        dataSource={tableData}
        rowKey={(record) => record.id}
        loading={loading}
        bordered
        scroll={{
          x: 600,
          y: 500,
        }}
        className="linkedin"
        onChange={handleTableChange}
        pagination={tableParams.pagination}
      >
        <Column
          title="Job Title"
          dataIndex="job_title"
          key="job_title"
          width="200px"
          ellipsis
          {...getColumnSearchProps('job_title')}
          sorter
        />
        <Column
          title="Company Name"
          dataIndex="company_name"
          key="company_name"
          width="210px"
          ellipsis
          {...getColumnSearchProps('company_name')}
          sorter
        />
        <Column
          title="Location"
          dataIndex="location"
          key="location"
          width="200px"
          ellipsis
          {...getColumnSearchProps('location')}
          sorter
        />
        <Column
          title="Posted On"
          dataIndex="posted_on"
          key="posted_on"
          width="160px"
          ellipsis
          {...getColumnSearchProps('posted_on')}
        />
        <Column
          title="Job Type"
          dataIndex="job_type"
          key="job_type"
          width="150px"
          ellipsis
          sorter
          {...getColumnSearchProps('job_type')}
        />
        <Column
          title="Experience Level"
          dataIndex="experience_level"
          key="experience_level"
          width="225px"
          ellipsis
          sorter
          {...getColumnSearchProps('experience_level')}
        />
        <Column
          title="Employees"
          dataIndex="employees"
          key="employees"
          width="140px"
          {...getColumnSearchProps('employees')}
        />
        <Column
          title="Applicants"
          dataIndex="applicants"
          key="applicants"
          width="140px"
          {...getColumnSearchProps('applicants')}
        />
        <Column
          title="Link"
          key="link"
          fixed="right"
          render={(_, record) => (
            <Space size="middle">
              <a
                href={String(record?.url?.replace(`('`, '')?.replace(`',)`, ''))}
                target="_blank"
                rel="noreferrer"
              >
                <LinkOutlined className="job-link" />
              </a>
            </Space>
          )}
          width="80px"
        />
      </Table>
      {openNewModal && (
        <NewScanModal
          openModal={openNewModal}
          setOpenModal={setOpenNewModal}
          setLoader={setLoader}
          onFinish={onFinish}
        />
      )}
      {openHistoryModal && (
        <ViewHistoryModal openModal={openHistoryModal} setOpenModal={setOpenHistoryModal} />
      )}
    </div>
  );
};
export default LinkedIn;
