import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Country, State, City } from 'country-state-city';

const NewScanModal = (props) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [form] = Form.useForm();

  const countries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  useEffect(() => {
    setStates(
      selectedCountry
        ? State.getStatesOfCountry(selectedCountry).map((state) => ({
            value: state.isoCode,
            label: state.name,
          }))
        : State.getAllStates().map((state) => ({
            value: state.name,
            label: state.name,
          }))
    );
  }, [selectedCountry]);

  useEffect(() => {
    setCities(
      selectedState
        ? City.getCitiesOfState(selectedCountry, selectedState).map((city) => ({
            value: city.name,
            label: city.name,
          }))
        : City.getAllCities('PK').map((city) => ({
            value: city.name,
            label: city.name,
          }))
    );
  }, [selectedCountry, selectedState]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleCancel = () => {
    props.setOpenModal(false);
  };

  const openNotification = (data) => {
    notification.warning({
      message: data,
    });
  };

  const onFinish = async (values) => {
    const location =
      (values.city !== undefined ? values.city : '') +
      (values.state !== undefined
        ? `, ${State.getStateByCodeAndCountry(values.state, values.country).name}`
        : '') +
      (values.country !== undefined ? `, ${Country.getCountryByCode(values.country).name}` : '');

    if (!values.keyword || !location) {
      openNotification('Required Fields Empty!');
      return;
    }

    props.setOpenModal(false);
    props.onFinish(values.keyword, location[0] === ',' ? location.slice(2) : location);
  };

  const onFinishFailed = (error) => {
    console.log('Failed:', error);
  };

  return (
    <>
      <Modal
        title="Scan Jobs"
        visible={props.openModal}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}
        className="modal scan-modal"
      >
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <span className="form-sub-heading">Job Keyword</span>
          <Form.Item label="Enter Keyword" name="keyword" style={{ marginTop: '10px' }}>
            <Input autoComplete="off" />
          </Form.Item>
          <span className="form-sub-heading">Location</span>
          <Form.Item label="Country" name="country" style={{ marginTop: '10px' }}>
            <Select
              value={selectedCountry}
              options={countries}
              onChange={handleCountryChange}
              placeholder="Select Country"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Select
              value={selectedState}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={states}
              onChange={handleStateChange}
              placeholder="Select State"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Select
              value={selectedCity}
              options={cities}
              onChange={handleCityChange}
              placeholder="Select City"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="form-button">
              Start Scanning
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default NewScanModal;
