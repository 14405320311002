import { Button, Modal, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { fetchLinkedinHistory } from 'API/Linkedin';
import { useEffect, useState } from 'react';

const ViewHistoryModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    const history = async () => {
      setLoading(true);
      const res = await fetchLinkedinHistory(tableParams);
      setData(res?.results);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });

      setLoading(false);
    };
    history();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleCancel = () => {
    props.setOpenModal(false);
  };
  return (
    <>
      <Modal
        title="Scannerd History"
        visible={props.openModal}
        onCancel={handleCancel}
        onOk={handleCancel}
        footer={null}
        className="history-modal modal"
      >
        <Table
          dataSource={data}
          loading={loading}
          bordered
          scroll={{
            y: 400,
          }}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        >
          <Column title="Keyword" dataIndex="keywords" width="125px" ellipsis />
          <Column title="Location" dataIndex="location" width="125px" ellipsis />
          <Column title="Jobs Fetched" dataIndex="fetched_jobs" width="130px" ellipsis />
          <Column title="Start Time" dataIndex="start_time" width="140px" ellipsis />
          <Column title="End Time" dataIndex="end_time" width="140px" ellipsis />
          <Column title="Status" dataIndex="status" width="125px" ellipsis />
        </Table>
      </Modal>
    </>
  );
};
export default ViewHistoryModal;
