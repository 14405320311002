import { useContext, useEffect } from 'react';
import { Row, Col, Button, Dropdown, Input, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import profilePhoto from '../../assets/images/profile.png';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { JobContext } from 'App';
const { Search } = Input;

const toggler = [
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" key={0}>
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const Header = (props) => {
  useEffect(() => window.scrollTo(0, 0));
  const history = useHistory();
  const { setFilter } = useContext(JobContext);

  const handleSubmit = (e) => {
    setFilter(e);
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          localStorage.removeItem('user');
          props.updateUser(null);
          history.push('/sign-in');
        }}
        style={{ textAlign: 'center', fontWeight: '600' }}
      >
        <NavLink to="">Sign Out</NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <div className="ant-page-header-heading">
            <span className="ant-page-header-heading-title" style={{ textTransform: 'capitalize' }}>
              {props.subName.replace('/', '')}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button type="link" className="sidebar-toggler" onClick={() => props.onPress()}>
            {toggler}
          </Button>

          <Dropdown trigger={'click'} overlay={dropdownMenu}>
            <Button className="profile">
              <img src={profilePhoto} alt="profile" className="profile-photo" />
              <span>Username</span>
            </Button>
          </Dropdown>
          <Search
            className="search-bar"
            placeholder={`Search in ${props.name.replace('/', '')}...`}
            // prefix={<SearchOutlined />}
            // onChange={handleSearchbar}
            onSearch={handleSubmit}
          />
        </Col>
      </Row>
    </>
  );
};

export default Header;
