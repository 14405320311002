import React from 'react';
import { Layout, Button, Row, Col, Typography, Form, Input, Modal } from 'antd';
import logo from '../assets/images/scannerd.png';
import { NavLink, Redirect, useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Header, Content } = Layout;

const SignIn = (props) => {
  const history = useHistory();

  const onFinish = (values) => {
    if (
      values.email === process.env.REACT_APP_EMAIL &&
      values.password === process.env.REACT_APP_PASSWORD
    ) {
      localStorage.setItem('user', values.email);
      props.updateUser(values.email);
      history.push('/linkedin');
    } else {
      Modal.error({
        title: 'Error',
        content: 'Wrong Credentials',
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin" style={{ height: '100vh' }}>
        <Header>
          <div className="brand" style={{ padding: '10px 0px', justifyContent: 'left' }}>
            <img src={logo} alt="logo" style={{ width: '144px' }} />
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8, offset: 0 }}
              md={{ span: 18 }}
              sm={{ span: 24 }}
              style={{
                border: '1px solid rgb(0 0 0 / 3%)',
                borderRadius: '20px',
                boxShadow: '0 20px 27px rgb(0 0 0 / 8%)',
              }}
            >
              <Title className="mb-15">Sign In</Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SignIn;
