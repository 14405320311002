const { default: axios } = require('axios');

const getLinkedInData = async (tableParams, search) => {
  try {
    let filters = '';
    for (const x in tableParams.filters) {
      if (tableParams.filters?.[x]?.[0]) {
        filters = filters + `&${x}=${tableParams.filters[x]?.[0]}`;
      }
    }

    const sortBy = tableParams.order
      ? tableParams.order === 'ascend'
        ? String(tableParams.columnKey)
        : `-${tableParams.columnKey}`
      : 'id';
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}page=${tableParams.pagination.current}&page_size=${
        tableParams.pagination.pageSize
      }&sort_by=${sortBy}${search ? `&search=${search}` : ''}${filters}`
    );
    return res?.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const fetchLinkedinData = async (keyword, location) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_FETCH_URL}keywords=${keyword}&location=${location}`
    );
    return res?.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const fetchLinkedinHistory = async (tableParams) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HISTORY_URL}page_size=${tableParams.pagination.pageSize}&page=${tableParams.pagination.current}`
    );
    return res?.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { getLinkedInData, fetchLinkedinData, fetchLinkedinHistory };
