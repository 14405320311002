import { Switch, Route, Redirect } from 'react-router-dom';
import Main from './components/layout/Main';
import 'antd/dist/antd.css';
import './assets/styles/main.css';
import './assets/styles/responsive.css';
import LinkedIn from './pages/Linkedin';
import Slack from './pages/Slack';
import Upwork from './pages/Upwork';
import Fiverr from './pages/Fiverr';
import SignIn from './pages/SignIn';
import React, { useEffect, useState } from 'react';
require('dotenv').config();

export const JobContext = React.createContext();

function App() {
  const [user, setUser] = useState(localStorage.getItem('user'));
  const [path, setPath] = useState('/linkedin');
  const [filter, setFilter] = useState();

  // useEffect(() => {
  //   const pathName = window.location.pathname;
  //   if (user && pathName !== '/linkedin') {
  //     pathName === '/slack'
  //       ? setPath(() => pathName)
  //       : pathName === '/fiverr'
  //       ? setPath(() => pathName)
  //       : setPath(() => '/upwork');
  //   }
  // }, []);

  return (
    <div className="App">
      <JobContext.Provider value={{ filter, setFilter }}>
        {!user ? (
          <Switch>
            <Route exact="/sign-in">
              <SignIn updateUser={setUser} />
            </Route>
            <Redirect from="/*" to="/sign-in" />
          </Switch>
        ) : (
          <Main updateUser={setUser}>
            <Switch>
              <Route path="/linkedin" component={LinkedIn} />
              <Route path="/upwork" component={Upwork} />
              <Route path="/slack" component={Slack} />
              <Route path="/fiverr" component={Fiverr} />
              <Route path="*">
                <Redirect to="linkedin" />
              </Route>
            </Switch>
          </Main>
        )}
      </JobContext.Provider>
    </div>
  );
}

export default App;
