const Fiverr = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <h1
        style={{
          fontSize: '30px',
          fontWeight: '800',
          marginTop: '30%',
          opacity: '0.5',
        }}
      >
        Under Development
      </h1>
    </div>
  );
};
export default Fiverr;
