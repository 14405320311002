import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Drawer, Affix } from 'antd';
import Sidenav from './Sidenav';
import Header from './Header';

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children, updateUser }) {
  const [visible, setVisible] = useState(false);
  const [sidenavColor, setSidenavColor] = useState('#1890ff');
  const openDrawer = () => setVisible(!visible);
  const handleSidenavColor = (color) => setSidenavColor(color);

  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  return (
    <Layout className={`layout-dashboard`}>
      <Drawer
        title={false}
        placement={'left'}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={'left'}
        width={250}
        className={`drawer-sidebar`}
      >
        <Layout className={`layout-dashboard`}>
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary "active-route"`}
            style={{ background: '#fff' }}
          >
            <Sidenav color={sidenavColor} setVisible={setVisible} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary "active-route"`}
        style={{ background: '#fff' }}
      >
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout>
        <Affix>
          <AntHeader className={'ant-header-fixed'}>
            <Header
              updateUser={updateUser}
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
            />
          </AntHeader>
        </Affix>

        <Content className="content-ant">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default Main;
